import * as React from 'react';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import { V1GeneralLedgerAccountT } from '@kwara/models/src';
import { SubscribedCombobox } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';

import { useGlAccounts } from '../../../hooks';

export const V1GeneralLedgerCombobox = ({
  name,
  labelId,
  accounts,
  required,
  compact,
  margin
}: {
  name: string;
  labelId?: string;
  accounts: V1GeneralLedgerAccountT[];
  required: boolean;
  compact?: boolean;
  margin?: boolean;
}) => {
  const glAccounts = sortBy(account => account.glCode, accounts);
  const comboboxData = map(
    account => ({ label: `${account.glCode} - ${account.name}`, value: account.glCode }),
    glAccounts
  );

  return (
    <SubscribedCombobox
      compact={compact}
      margin={margin}
      placeholderId="GL Accounts"
      size={'medium'}
      name={name}
      labelId={labelId}
      data={comboboxData}
      required={required}
    />
  );
};

export const V1AllGeneralLedgerAccountCombobox = ({
  name,
  labelId,
  required,
  margin,
  compact
}: {
  name: string;
  labelId?: string;
  required?: boolean;
  margin?: boolean;
  compact?: boolean;
}) => {
  const r = useGlAccounts();

  return (
    <Loadable {...r}>
      {(accounts: V1GeneralLedgerAccountT[]) => (
        <V1GeneralLedgerCombobox
          required={required}
          name={name}
          labelId={labelId}
          accounts={accounts}
          margin={margin}
          compact={compact}
        />
      )}
    </Loadable>
  );
};
