import * as React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import includes from 'lodash/fp/includes';
import get from 'lodash/fp/get';

import { ExpanderRow } from '@kwara/components/src';
import { UserT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { ComponentProps } from '@kwara/components/src/Wizard/deprecated/SubStep';
import { If } from '@kwara/components/src/If/If';

import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';
import { SubsectionTitle } from '../../../../components/DetailSubsection';

import { Email } from './components/Email';
import { Name } from './components/Name';
import { Roles } from './components/Roles';
import { Password } from './components/Password';
import { PhoneNumber } from './components/PhoneNumber';
import { MFA } from './components/MFA';
import { SaccoSettings } from '../Sacco';
import { BodyContentWrapper } from '../../../../layouts';
import { useAuth } from '../../../../hooks';

import { Header } from '../Header';

export type SettingProps = ComponentProps<{}>;

export function Title({ titleId, classNames }: { titleId: string; classNames?: string }) {
  return (
    <h4 className={cx('kw-text-large kw-weight-bold mt0 mb0', classNames)}>
      <If condition={!!titleId} do={<Text id={titleId} />} />
    </h4>
  );
}

export function DetailWrapper({ children }: { children: React.ReactNode }) {
  return <div className="pv4 bb b--light-grey-400">{children}</div>;
}

export function Detail({ titleId, value }: { titleId: string; value: string | React.ReactElement }) {
  return (
    <>
      <Title titleId={titleId} classNames="mb1" />
      <span className="grey-400">{value || '-'}</span>
    </>
  );
}

type SupportedHiddenSection = 'mfa';
type SupportedEditableSectionsT = 'roles';
export function ProfileContent({
  user,
  editableSections = [],
  hiddenSections = []
}: {
  user: UserT;
  editableSections?: SupportedEditableSectionsT[];
  hiddenSections?: SupportedHiddenSection[];
}) {
  const canEdit = (section: SupportedEditableSectionsT) => includes(section, editableSections);
  const isHidden = (section: SupportedHiddenSection) => includes(section, hiddenSections);

  return (
    <>
      <Name user={user} />
      <Roles user={user} isEditable={canEdit('roles')} />
      <If condition={!isHidden('mfa')} do={<MFA user={user} />} />
      <PhoneNumber user={user} />
      <Email user={user} />
      <Password />
    </>
  );
}

export const Personal = observer(() => {
  const store = useSaccoProfileContext();
  const { profile: user, refetch } = store;
  const organisation = get('organisation', user);
  const settings = get('organisation.organisationSetting', user);

  const auth = useAuth();
  const isV1 = auth.isV1();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <Header titleId="Settings.Personal.title" subtitleId="Settings.Personal.subtitle" />

          <SubsectionTitle>
            <Text id="Settings.Personal.subsection.personal" />
          </SubsectionTitle>
          <ExpanderRow isExpanded={isV1} ariaLabel={'PersonalSettings'}>
            <ProfileContent user={user} />
          </ExpanderRow>

          {!isV1 ? (
            <>
              <SubsectionTitle>
                <Text id="Settings.Personal.subsection.sacco" />
              </SubsectionTitle>
              <ExpanderRow ariaLabel={'SaccoSettings'}>
                <SaccoSettings refetch={refetch} settings={settings} organisation={organisation} />
              </ExpanderRow>
            </>
          ) : null}
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
});
