import React from 'react';

import OverviewListV2 from '../../components/OverviewListV2';

import { LoanList } from './components/LoanList';
import { BodyContentWrapper } from '../../layouts';
import { usePaginatedLoans } from './usePaginatedLoans';
import { useV1Filter } from './filters/useV1Filter';
import { useLegacyFilter } from './filters/useLegacyFilter';
import { Indicators } from './components/Indicators/Indicators';
import { usePermissions, useAuth } from '../../hooks';

function InternalLoans({ isV1 }: { isV1: boolean }) {
  const { AppPermissions, permission } = usePermissions();
  const {
    isLoading,
    isFetchingNextPage,
    allData,
    fetchNextPage,
    totalResults,
    hasNextPage,
    error
  } = usePaginatedLoans();

  const loading = isLoading || isFetchingNextPage;

  return (
    <BodyContentWrapper.Root headId="LoanList.title">
      {permission.to(AppPermissions.ViewLoanMetrics) && !isV1 ? (
        <BodyContentWrapper.Row.Root>
          <BodyContentWrapper.Row.Indicators>
            <Indicators />
          </BodyContentWrapper.Row.Indicators>
        </BodyContentWrapper.Row.Root>
      ) : null}
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <LoanList
            loading={loading}
            errors={error}
            loans={allData}
            hasMore={hasNextPage}
            onLoadMoreData={fetchNextPage}
            totalNumResults={totalResults}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function Loans() {
  const auth = useAuth();
  const isV1 = auth.isV1();

  const useFilter = isV1 ? useV1Filter : useLegacyFilter;
  const filters = useFilter();

  return (
    <OverviewListV2.Root filters={filters}>
      <InternalLoans isV1={isV1} />
    </OverviewListV2.Root>
  );
}
