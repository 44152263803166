import React from 'react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { useBoolean } from '@kwara/lib/src/hooks';
import { Panel } from '@kwara/components/src/Panel';
import { LinkButton } from '@kwara/components/src/LinkButton';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { DatePicker, TextField, Field } from '@kwara/components/src/Form';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';
import { LoanProductChargeT, CHARGE_CALCULATION_TYPES, CHARGE_TIMES } from '@kwara/models/src/models/v1/LoanProducts';

import { LoanAddData } from '../..';

import styles from './EditChargeWidget.module.css';

export type OnChangeCharge = (chargeId: string, key: string, value: string) => Promise<LoanAddData>;

type EditChargeWidgetProps = {
  chargePrefix: string;
  charge: LoanProductChargeT;
  anticipatedDisbursementDate: string;
  onChangeCharge: OnChangeCharge;
};

export function EditChargeWidget({
  anticipatedDisbursementDate,
  charge,
  chargePrefix,
  onChangeCharge
}: EditChargeWidgetProps) {
  const [isModalOpen, { setToFalse, setToTrue }] = useBoolean();

  const amountEditable = charge.calculationType === CHARGE_CALCULATION_TYPES.flat;
  const dueDateEditable = charge.chargeTime === CHARGE_TIMES.specifiedDueDate;
  const disabledClose = (amountEditable && !charge.amount) || (dueDateEditable && !charge.dueDate);
  const disableEdit = !amountEditable && !dueDateEditable;

  return (
    <>
      <LinkButton as="button" disabled={disableEdit} onClick={setToTrue}>
        <Text id="General.Edit" />
      </LinkButton>
      {isModalOpen && (
        <Overlay opacityPercentage={90}>
          <ModalWrapper
            elementType="editchargewidget"
            className={cx(styles['container'], zIndices.Max)}
            onClose={setToFalse}
          >
            <LinkButton as="button" disabled={disabledClose} onClick={setToFalse}>
              <Text id="LoanAdd.Charges.EditChargeWidget.cancel" />
            </LinkButton>
            <div className="mt5 flex items-center justify-center">
              <Panel>
                <h1 className="kw-text-medium kw-weight-bold">Edit {charge.name}</h1>
                {amountEditable && (
                  <Field labelId="LoanAdd.Charges.EditChargeWidget.amount">
                    <TextField
                      required
                      isCurrency
                      type="number"
                      name={`${chargePrefix}.amount`}
                      value={charge.amount}
                      leftGlyph="Currency.orgCurrency"
                      className={styles['amount']}
                      onChange={({ target }) => onChangeCharge(charge.chargeId, 'amount', target.value)}
                    />
                  </Field>
                )}
                {dueDateEditable && (
                  <Field labelId="LoanAdd.Charges.EditChargeWidget.dueDate">
                    <DatePicker
                      required
                      name={`${chargePrefix}.dueDate`}
                      value={charge.dueDate}
                      disabledDays={{ before: new Date(anticipatedDisbursementDate) }}
                      onChange={value => onChangeCharge(charge.chargeId, 'dueDate', value)}
                    />
                  </Field>
                )}
                <Button type="primary" disabled={disabledClose} className="mt3" onClick={setToFalse}>
                  <Text id="LoanAdd.Charges.EditChargeWidget.saveAndClose" />
                </Button>
              </Panel>
            </div>
          </ModalWrapper>
        </Overlay>
      )}
    </>
  );
}
