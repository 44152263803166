import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export enum CHARGE_TIMES {
  specifiedDueDate = 'Specified due date',
  disbursement = 'Disbursement'
}

export enum CHARGE_CALCULATION_TYPES {
  flat = 'Flat'
}

export const Charge = Base.extend({
  static: {
    jsonapiType: 'loan_charges'
  },
  attrs: {
    name: attr(),
    penalty: attr(),
    calculationType: attr(),
    chargeTime: attr(),

    // write
    chargeId: attr(),
    amount: attr(),
    dueDate: attr()
  }
});

export interface LoanProductChargeT extends Omit<InferredModel<typeof Charge>, 'errors'> {
  name: string;
  amount: string;
  penalty: boolean;
  calculationType: string;
  chargeTime: string;
  chargeId: string;
  dueDate: string;
}
