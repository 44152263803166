import { useState, useCallback } from 'react';
import last from 'lodash/fp/last';
import pick from 'lodash/fp/pick';
import compact from 'lodash/fp/compact';

import { LoanType } from '@kwara/models/src/models/Loan';
import { MemberType } from '@kwara/models/src/models/Member';
import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { Loan, snakeCaseObjectKeys } from '@kwara/models/src';
import { setRemittanceDetails, setDisbursementBankDetails } from '@kwara/lib/src/remittance';

import { LoanAddData } from '.';
import { Store } from '../../../models/Store/Store';
import { getSelectedFeeApplications } from '../hooks/useFeeValues';
import { useDisbursementModeAdapter } from './useDisbursementModeAdapter';
import { prepareNotes, setGuarantors, saveMember, getLoanApplication } from './utils';

async function uploadAttachments(member: MemberType) {
  const uploaded = await saveAttachments(member, member.attachments);

  if (!uploaded) throw member.errors;
}

async function saveLoan(loan: LoanType) {
  const withIt = [
    'guarantors',
    'loanApplication',
    { collaterals: ['attachments'] },
    { remittance: ['bankAccount', 'collectingBank.id'] }
  ];
  const success = await loan.save({ with: withIt });

  if (!success) throw loan.errors;
}

export function useLegacyLoanAdd() {
  const [createdLoan, setCreatedLoan] = useState<LoanType | null>(null);
  const disbursementModes = useDisbursementModeAdapter();

  const createLoan = useCallback(
    async (data: LoanAddData, store: Store) => {
      const { member, remittance, bankAccounts, product, amount, payOffLoans, loanDuration, repaymentPeriod } = data;
      const loanApplication = getLoanApplication(member);
      setRemittanceDetails({ remittance, data, bankAccounts, store });
      const loan = new Loan({
        amount,
        payOffLoans,
        remittance,
        loanDuration,
        repaymentPeriod,
        repaymentPeriodUnit: data.repaymentPeriodUnit,
        loanApplication,
        productId: product.id,
        accountHolderId: member.id,
        applicationNotes: prepareNotes(data.notes),
        disbursementMode: disbursementModes[data.disbursementMode],
        loanClassificationId: last(compact(data.classifications)),
        disbursementBankDetails: setDisbursementBankDetails(data, store),
        guarantors: setGuarantors(data.guarantors, data.selfGuaranteeAmount, member.id),
        feeApplications: getSelectedFeeApplications({
          fees: product.fees,
          feeApplications: data.feeApplications
        }).map(snakeCaseObjectKeys),
        ...pick(['purpose', 'specification', 'collaterals'], data)
      });

      saveMember(member);
      uploadAttachments(member);
      await saveLoan(loan);
      setCreatedLoan(loan);
    },
    [disbursementModes]
  );

  return { createLoan, createdLoan };
}
