import { useCallback } from 'react';
import find from 'lodash/fp/find';
import pick from 'lodash/fp/pick';
import includes from 'lodash/fp/includes';

import { CHARGE_TIMES } from '@kwara/models/src/models/v1/LoanProducts';

import { useInitializeValues, ArgType } from './useInitializeValues';

export function useChargesTable({ chargeOptions, charges, anticipatedDisbursementDate, onChange }: ArgType) {
  useInitializeValues({ chargeOptions, charges, anticipatedDisbursementDate, onChange });

  const getChargeOptionById = useCallback(
    (id: string) => find(option => !option.penalty && option.id === id, chargeOptions),
    [chargeOptions]
  );

  const getChargeById = useCallback((id: string) => find(charge => charge.chargeId === id, charges), [charges]);

  const selectedCharges = charges.map(charge => {
    const option = getChargeOptionById(charge.chargeId);
    // merge the static charge attributes with the incoming "live" values,
    // so it's ready to be displayed on the table
    return { ...pick(['name', 'chargeTime', 'calculationType'], option), ...charge };
  });

  const chargeIds = charges.map(charge => charge.chargeId);

  const selectableChargeOptions = chargeOptions.filter(option => {
    return !option.penalty && !includes(option.id, chargeIds);
  });

  const onRemove = useCallback(
    (chargeId: string) => {
      const chargeToRemove = { ...getChargeById(chargeId) };
      chargeToRemove.isMarkedForDestruction = true;
      const updatedCharges = charges.map(charge => {
        if (charge.chargeId === chargeId) {
          return chargeToRemove;
        } else {
          return { ...charge };
        }
      });
      return onChange({ charges: updatedCharges });
    },
    [onChange, charges, getChargeById]
  );

  const onRestore = useCallback(
    (chargeId: string) => {
      const chargeToRestore = { ...getChargeById(chargeId) };
      chargeToRestore.isMarkedForDestruction = false;
      const updatedCharges = charges.map(charge => {
        if (charge.chargeId === chargeId) {
          return chargeToRestore;
        } else {
          return { ...charge };
        }
      });
      return onChange({ charges: updatedCharges });
    },
    [onChange, charges, getChargeById]
  );

  const onAdd = useCallback(
    (chargeId: string) => {
      const option = getChargeOptionById(chargeId);
      const isSpecifiedDueDate = option.chargeTime === CHARGE_TIMES.specifiedDueDate;
      const dueDate = isSpecifiedDueDate ? anticipatedDisbursementDate : null;
      const chargeToAdd = { ...option, chargeId, dueDate };
      const updatedCharges = [...charges, chargeToAdd];

      return onChange({ charges: updatedCharges });
    },
    [getChargeOptionById, anticipatedDisbursementDate, charges, onChange]
  );

  const onChangeCharge = useCallback(
    (chargeId: string, key: string, value: string) => {
      const updatedCharges = charges.map(charge => {
        if (chargeId === charge.chargeId) {
          return { ...charge, [key]: value };
        } else {
          return { ...charge };
        }
      });

      return onChange({ charges: updatedCharges });
    },
    [charges, onChange]
  );

  return {
    selectableChargeOptions,
    selectedCharges,
    onRemove,
    onAdd,
    onRestore,
    onChangeCharge
  };
}
