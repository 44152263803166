import React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';

import { Text } from '@kwara/components/src/Intl';
import { SubscribedDatePicker, SubscribedCombobox } from '@kwara/components/src/Form';
import {GlAccountType} from "../../../../../shared-models/src/models/AccountingReport";

type Payload = { filters: { from: string; to: string; gl_account?: string | null } };

type Props = {
  onGenerate(data: Payload): void;
  glAccounts: GlAccountType[];
  isLoadingGlAccounts: boolean;
};

const rules = {
  'filters.from': {
    presence: { allowEmpty: false, message: 'From date is required' }
  },
  'filters.to': {
    presence: { allowEmpty: false, message: 'To date is required' }
  }
};

export function Form({ onGenerate, glAccounts, isLoadingGlAccounts }: Props) {
  const comboboxData = glAccounts.map(gl_account => ({ value: gl_account.glCode, label: `${gl_account.glCode} - ${gl_account.name}` }));

  return (
    <ReactFinalForm
      onSubmit={(data: Payload, form) => {
        onGenerate(data);
        form.reset();
      }}
      validate={createValidator(rules)}
      render={({ form, handleSubmit, valid, submitting }) => {
        return (
          <form
            className="w-100 flex justify-left items-center"
            onSubmit={handleSubmit}
            aria-label="Create Journal Entries Report Form"
          >
            <div className="relative dib mr3">
              <SubscribedCombobox
                compact
                margin={false}
                size="medium"
                placeholderId="JournalEntriesReports.Form.glAccount.label"
                name="filters.gl_account"
                data={comboboxData}
              />
            </div>
            <div className="relative dib mr3">
              <SubscribedDatePicker
                compact
                margin={false}
                showInfo={false}
                required
                name="filters.from"
                leftGlyph="JournalEntriesReports.Form.fromDate.label"
              />
            </div>

            <div className="relative dib mr3">
              <SubscribedDatePicker
                compact
                margin={false}
                showInfo={false}
                required
                name="filters.to"
                leftGlyph="JournalEntriesReports.Form.toDate.label"
              />
            </div>

            <Button disabled={!valid || submitting || isLoadingGlAccounts} type="primary" onClick={form.submit}>
              <Text id="JournalEntriesReports.Form.submit.generate"/>
            </Button>
          </form>
        );
      }}
    ></ReactFinalForm>
  );
}
