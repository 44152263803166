import { Charge, CHARGE_CALCULATION_TYPES, CHARGE_TIMES } from './Charge';

export { CHARGE_CALCULATION_TYPES, CHARGE_TIMES };

export type { LoanProductChargeT } from './Charge';

const LoanProduct = {
  Charge: Charge
};

export default LoanProduct;
