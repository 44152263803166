import { attr, belongsTo, hasMany, hasOne } from 'spraypaint';
import axios from 'axios';
import pipe from 'lodash/fp/pipe';
import some from 'lodash/fp/some';
import get from 'lodash/fp/get';

import { Logger } from '@kwara/lib/src/logger';
import { ValueOf } from 'GlobalTypes';

import { MemberType, SavingsTransactionType } from '..';
import filterEmptyValues from '../lib/filterEmptyValues';
import Base, { BaseModel } from './Base';
import { AuthorizationHoldT, LatestPendingAuthorizationHold } from './AuthorizationHold';
import { fields, SavingRemittance, SavingRemittanceT } from './Remittance';
import cameliseObjectKeys from '../lib/cameliseObjectKeys';
import { snakeCaseObjectKeys } from '../lib/snakeCaseObjectKeys';
import { ProductTypes, SavingProductType } from './SavingProduct';
import createModelErrors, { createErrorsFromApiResponse } from './createModelErrors';

export const LegacySavingStates = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  ACTIVE: 'ACTIVE',
  ACTIVE_IN_ARREARS: 'ACTIVE_IN_ARREARS',
  MATURED: 'MATURED',
  LOCKED: 'LOCKED',
  DORMANT: 'DORMANT',
  CLOSED: 'CLOSED',
  CLOSED_WRITTEN_OFF: 'CLOSED_WRITTEN_OFF',
  WITHDRAWN: 'WITHDRAWN',
  CLOSED_REJECTED: 'CLOSED_REJECTED'
};

export const V1SavingStates = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  LIVE: 'LIVE',
  CLOSED: 'CLOSED',
  REJECTED: 'REJECTED'
};

export const SavingStates = {
  ...LegacySavingStates,
  ...V1SavingStates
};

export const SavingEvents = Object.freeze({
  CLOSE: 'close'
});

export type SavingState = ValueOf<typeof SavingStates>;
export type SavingEvent = ValueOf<typeof SavingEvents>;

type Note = {
  notes: string | null;
};

const Saving = Base.extend({
  static: {
    jsonapiType: 'savings'
  },
  attrs: {
    activationDate: attr(),
    name: attr(),
    accruedInterest: attr(),
    balance: attr(),
    interest: attr(),
    type: attr(),
    state: attr({ persist: false }),
    fees: attr(),
    transactions: hasMany(),
    latestPendingAuthorizationHold: hasOne({
      type: LatestPendingAuthorizationHold
    }),

    remittance: hasOne({ type: SavingRemittance }),

    // Create
    accountHolderId: attr(),
    productId: attr(),
    amount: attr(),

    member: belongsTo(),
    product: belongsTo('savings_products'),

    payrollDeductionDetails: attr(),

    //v1 specific attributes
    memberName: attr()
  },
  methods: {
    deserialize() {
      // This field is stored as a string in Mambu, but needs
      // to be a date for the DatePicker to understand it

      const startDate = get(fields.remittance.startDate, this);
      if (startDate) {
        this.remittance.startDate = new Date(startDate);
      }

      return this;
    },
    isBridgingProduct() {
      return get('product.isBridgingProduct', this);
    },
    availableBalance() {
      return get('availableBalance', this.latestPendingAuthorizationHold) || this.balance;
    },
    isPendingApproval() {
      return this.state.current === SavingStates.PENDING_APPROVAL;
    },
    isActive() {
      return this.state.current === SavingStates.ACTIVE;
    },
    isLive() {
      return this.state.current === SavingStates.LIVE;
    },
    isApproved() {
      return [SavingStates.ACTIVE, SavingStates.APPROVED].includes(this.state.current);
    },
    isClosed() {
      return [SavingStates.CLOSED].includes(this.state.current);
    },
    isWithdrawable() {
      if (this.isLive() && this.balance > 0) return true;

      return (
        this.isActive() &&
        [ProductTypes.CURRENT_ACCOUNT, ProductTypes.SAVINGS_ACCOUNT].includes(this.product.productType) &&
        !this.isBridgingProduct()
      );
    },
    canBeClosed() {
      return (
        this.isEventPermitted(SavingEvents.CLOSE) &&
        [SavingStates.ACTIVE, SavingStates.DORMANT, SavingStates.LIVE].includes(this.state.current) &&
        this.balance === 0 &&
        !this.isBridgingProduct()
      );
    },
    canBeReopened() {
      return [SavingStates.CLOSED].includes(this.state.current);
    },
    canAddPenalty() {
      return !this.isBridgingProduct() && [SavingStates.ACTIVE, SavingStates.LIVE].includes(this.state.current);
    },
    transactionsPermitted() {
      return (
        [SavingStates.ACTIVE, SavingStates.LIVE, SavingStates.ACTIVE_IN_ARREARS, SavingStates.DORMANT].includes(
          this.state.current
        ) && !this.isBridgingProduct()
      );
    },
    // TODO: Find better way of doing this
    interestObject() {
      return cameliseObjectKeys(this.interest);
    },
    async close({ notes }: { notes: string | null }): Promise<boolean> {
      if (this.isEventPermitted(SavingEvents.CLOSE)) {
        return await this.transition(SavingEvents.CLOSE, { notes });
      }

      this.errors = createModelErrors({
        base: 'APP_SAVING_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async reopen() {
      const url = `${Base.baseUrl}/reopen_account`;
      const opts = Saving.fetchOptions();
      const data = { data: { attributes: { id: this.id } } };

      return axios.post(url, data, opts).then(res => res.data);
    },
    isEventPermitted(event: SavingEvent) {
      return some({ name: event }, get('permitted_events', this.state));
    },
    async transition(event: SavingEvent, params: Note | null) {
      const url = `${Saving.url(this.id)}/state`;
      const attributes = pipe(
        filterEmptyValues,
        snakeCaseObjectKeys
      )({
        event,
        ...params
      });

      const options = {
        ...Saving.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error('Error transitioning saving states', JSON.stringify(errors));
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

interface SavingsRemittanceT {
  remittance_method: string;
  remittance_frequency: string;
  remittance_collection_date: string;
  remittance_fee_amount: string;
  direct_debit_details: {
    remittance_direct_debit_bank: string;
    remittance_direct_debit_branch: string;
    remittance_direct_debit_account: string;
  };
  payroll_deduction_details: {
    payroll_deduction_employer_name: string;
    payroll_deduction_staff_id: string;
  };
}

export interface SavingType extends BaseModel<SavingType> {
  id: string;
  transactions: SavingsTransactionType[];
  activationDate: string;
  amount: number;
  member: MemberType;
  balance: number;
  accruedInterest?: number;
  name: string;
  updatedAt?: string;
  createdAt: string;
  product: SavingProductType;
  latestPendingAuthorizationHold: AuthorizationHoldT;
  availableBalance: () => number;
  isApproved: () => boolean;
  isClosed: () => boolean;
  canBeClosed: () => boolean;
  isBridgingProduct: () => boolean;
  transactionsPermitted: () => boolean;
  isWithdrawable: () => boolean;
  interestObject: () => {
    rate: number;
  };
  // This looks horrible but that's the way the model is now, when posting the remittance options
  // are nested under this key, when getting they are flattened to the top level.
  remittanceOptions?: SavingsRemittanceT;
  remittanceMethod: string;
  remittance: SavingRemittanceT;
  monthlyRemittanceAmount: string;
  directDebitDetails: {
    remittance_direct_debit_bank: string;
    remittance_direct_debit_branch: string;
    remittance_direct_debit_account: string;
  };
  payrollDeductionDetails: {
    payroll_deduction_employer_name: string;
    payroll_deduction_staff_id: string;
  };

  state: {
    current: string;
    permitted_events: { name: SavingEvent }[];
  };

  //v1 specific attributes
  memberName?: string;
}

export default Saving;
