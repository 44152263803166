import toLower from 'lodash/fp/toLower';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { LoanProductChargeT } from '@kwara/models/src/models/v1/LoanProducts';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';

function addChargesQueryString(chargesParams: URLSearchParams, charges: LoanProductChargeT[] = []) {
  charges
    .filter(charge => !charge.isMarkedForDestruction)
    .forEach(({ chargeId, amount, dueDate }) => {
      /**
       * The Rails server expects an array of objects to be encoded
       * in this format when sending it as a URL query string:
       * e.g., "charges[][object1_key]=object1_value&charges[][object2_key]=object2_value"
       */
      chargesParams.append('charges[][charge_id]', chargeId);
      chargesParams.append('charges[][amount]', amount.toString());

      if (dueDate) {
        chargesParams.append('charges[][due_date]', formatIsoDate(dueDate));
      }
    });
}

export type QueryParams = {
  amount: string;
  memberId: string;
  productId: string;
  repaymentPeriod: string;
  loanDuration: string;
  submittedAt: string;
  firstRepaymentDate: string;
  anticipatedDisbursementDate: string;
  repaymentPeriodUnit: string;
  charges: LoanProductChargeT[];
};

function createScheduleQueryParams({
  amount,
  memberId,
  productId,
  repaymentPeriod,
  loanDuration,
  firstRepaymentDate,
  anticipatedDisbursementDate,
  submittedAt,
  repaymentPeriodUnit,
  charges
}: QueryParams) {
  const repaymentInstallments = calculateNumberInstallments({
    period: Number(repaymentPeriod),
    loanDuration: Number(loanDuration)
  });

  const scheduleQueryParams = new URLSearchParams();
  scheduleQueryParams.append('amount', amount);
  scheduleQueryParams.append('member_id', memberId);
  scheduleQueryParams.append('product_id', productId);
  scheduleQueryParams.append('repayment_period', repaymentPeriod);
  scheduleQueryParams.append('repayment_installments', repaymentInstallments.toString());
  scheduleQueryParams.append('first_repayment_date', formatIsoDate(firstRepaymentDate));
  scheduleQueryParams.append('anticipated_disbursement_date', formatIsoDate(anticipatedDisbursementDate));
  scheduleQueryParams.append('repayment_period_unit', toLower(repaymentPeriodUnit));
  scheduleQueryParams.append('submitted_at', formatIsoDate(submittedAt));
  addChargesQueryString(scheduleQueryParams, charges);

  return scheduleQueryParams.toString();
}

export { addChargesQueryString, createScheduleQueryParams };
