import * as React from 'react';
import map from 'lodash/fp/map';

import { HttpErrorObject } from 'GlobalTypes';

import { JournalEntryT } from '@kwara/models/src/models/JournalEntry';

import { V1TableRow } from './V1TableRow';
import { useAuth } from '../../../../../hooks';
import { LegacyTableRow } from './LegacyTableRow';
import Table, { Heading, Row, Footer } from '../../../../../components/Table';

type JournalEntriesTableProps = {
  hasMore: boolean;
  isLoading: boolean;
  transactions: JournalEntryT[];
  errors?: HttpErrorObject[];
  onNext?(): void;
};

export function JournalEntriesTable({ transactions, ...r }: JournalEntriesTableProps) {
  const auth = useAuth();

  const isV1 = auth.isV1();

  return (
    <Table
      heading={
        <Row>
          <Heading width="100px" translationId="Finance.JournalEntries.Table.header.id" />
          <Heading translationId="Finance.JournalEntries.Table.header.valueDate" />
          { !isV1 && (<Heading translationId="Finance.JournalEntries.Table.header.createdAt" />)}
          <Heading translationId="Finance.JournalEntries.Table.header.transactionID" />
          <Heading translationId="Finance.JournalEntries.Table.header.glAccount" />
          <Heading translationId="Finance.JournalEntries.Table.header.branch" />
          <Heading align="right" translationId="Finance.JournalEntries.Table.header.debit" />
          <Heading align="right" translationId="Finance.JournalEntries.Table.header.credit" />
          <Heading iconSpacer />
        </Row>
      }
      footer={<Footer {...r} colSpan={isV1 ? 8 : 9} items={transactions} />}
    >
      {map(
        (t: JournalEntryT) =>
          isV1 ? <V1TableRow key={t.id} journalEntry={t} /> : <LegacyTableRow key={t.id} journalEntry={t} />,
        transactions
      )}
    </Table>
  );
}
