import React from 'react';

import { TrialBalanceT } from '@kwara/models/src/models/v1/TrialBalance/Report';
import Empty from '@kwara/components/src/Empty';
import { Currency, Text } from '@kwara/components/src/Intl';
import Table, { Row, Heading, Footer, Cell } from '../../../../../components/Table';

interface TableT {
  isLoading: boolean;
  report: TrialBalanceT;
}

export function ReportsTable({ report, isLoading }: TableT) {
  const heading = React.useMemo(
    () => (
      <Row>
        <Heading translationId="AccountingReports.V1.TrialBalance.Table.header.glName" />
        <Heading translationId="AccountingReports.V1.TrialBalance.Table.header.glCode" />
        <Heading align="right" translationId="AccountingReports.V1.TrialBalance.Table.header.openingBalance" />
        <Heading align="right" translationId="AccountingReports.V1.TrialBalance.Table.header.debitAmount" />
        <Heading align="right" translationId="AccountingReports.V1.TrialBalance.Table.header.creditAmount" />
        <Heading align="right" translationId="AccountingReports.V1.TrialBalance.Table.header.netChange" />
        <Heading align="right" translationId="AccountingReports.V1.TrialBalance.Table.header.closingBalance" />
      </Row>
    ),
    []
  );

  if (!report) {
    return null;
  }

  const rows = report.rows || [];
  if (rows.length === 0) {
    return (
      <Table
        heading={heading}
        footer={
          <Row>
            <Cell colSpan={6}>
              <Empty>
                <Text id="AccountingReports.V1.TrialBalance.Table.footer.empty" />
              </Empty>
            </Cell>
          </Row>
        }
      />
    );
  }

  return (
    <Table heading={heading} footer={<Footer colSpan={4} hasMore={false} isLoading={isLoading} items={rows} />}>
      {rows.map(row => {
        return (
          <Row key={row.id}>
            <Cell>{row.glName}</Cell>
            <Cell>{row.glCode}</Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={row.openingBalance} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={row.debitAmount} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={row.creditAmount} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={row.netChange} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={row.closingBalance} />
            </Cell>
          </Row>
        );
      })}

      <Row>
        <Cell />
        <Cell>
          <Text id="AccountingReports.TrialBalance.Cell.totals" />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={report.sumOpeningBalance} />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={report.sumDebitAmount} />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={report.sumCreditAmount} />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={report.sumNetChange} />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={report.sumClosingBalance} />
        </Cell>
      </Row>
    </Table>
  );
}
