import * as React from 'react';
import get from 'lodash/fp/get';
import compact from 'lodash/fp/compact';
import reject from 'lodash/fp/reject';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';

import { Member, MemberType, NextOfKin, NextOfKinT } from '@kwara/models/src';
import { MemberAttachment } from '@kwara/models/src/models/v1/MemberAttachment';

import { Loadable, EMPTY } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import { createContext } from '@kwara/lib/src/utils/createContext';
import { AttachmentT, saveAttachments } from '@kwara/models/src/models/Attachment';
import Wizard from '@kwara/components/src/Wizard/deprecated/Wizard';

import { steps } from './config';
import { memberPath } from '../../lib/urls';
import { WizardPageProps } from '..';
import { useAuth } from '../../hooks';

export type WizardData = MemberType;

const BUTTONS_APPEAR_AS = Object.freeze({ VERIFY: 'verify', NEXT: 'next' });

function mapNextOfKins(kins: NextOfKinT[]) {
  return pipe(
    reject((kin: NextOfKinT) => kin.isMarkedForDestruction),
    map(kin => new NextOfKin(kin))
  )(kins);
}

async function v1SaveAttachments(member: MemberType, attachments: AttachmentT[]) {
  return MemberAttachment.s3FullUploadAndAttachForMember(member, attachments);
}

async function saveMember(member: WizardData, isV1: boolean) {
  const physicalAddress = get('addresses.[0].physicalAddress', member);
  const postalAddress = get('addresses.[0].postalAddress', member);
  member.nextOfKins = mapNextOfKins(member.nextOfKins);

  const success = await member.save({
    with: compact([physicalAddress || postalAddress ? 'addresses' : null, 'idDocuments', 'nextOfKins'])
  });

  if (!success) throw member.errors;

  const uploadFiles = isV1 ? v1SaveAttachments : saveAttachments;

  const uploaded = await uploadFiles(member, member.attachments);

  if (!uploaded) throw member.errors;
}

const includes = ['addresses', 'next_of_kins', 'attachments', 'id_documents'];

function useMemberFormModel(id: string) {
  const result = useMember(id, includes);

  if (!id) return { data: Member.new(), error: EMPTY, isLoading: false };

  return result;
}

export type VerifyIdentityContextType = {
  appearAs: string;
  setAppearAs: React.Dispatch<React.SetStateAction<string>>;
};

const [VerifyIdentityContextProvider, useVerifyIdentityContext] = createContext<VerifyIdentityContextType>(
  'VerifyIdentity',
  true
);

function MemberAdd({ match, history, baseUrl }: WizardPageProps<{ memberId: string }>) {
  const auth = useAuth();
  const isV1 = auth.isV1();

  const result = useMemberFormModel(match.params.memberId);
  const [appearAs, setAppearAs] = React.useState(BUTTONS_APPEAR_AS.VERIFY);
  const verifyIdentityValue = React.useMemo(() => ({ appearAs, setAppearAs }), [appearAs]);

  return (
    <Loadable {...result}>
      {member => {
        member.nextOfKins = [new NextOfKin({})];

        return (
          <VerifyIdentityContextProvider value={verifyIdentityValue}>
            <Wizard
              analyticsId="AddMember"
              baseUrl={baseUrl}
              history={history}
              currentStep={match.params.step}
              initialData={member}
              currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
              cancelReturnsTo={memberPath({ id: get('id', member) })}
              onSubmit={data => saveMember(data, isV1)}
              steps={steps(appearAs, isV1)}
              startId="about"
              titleId="AddMember.title"
            />
          </VerifyIdentityContextProvider>
        );
      }}
    </Loadable>
  );
}

export { MemberAdd, BUTTONS_APPEAR_AS, VerifyIdentityContextProvider, useVerifyIdentityContext, v1SaveAttachments };
