import { formatHumanDate } from '@kwara/lib/src/dates';
import { MemberType } from '@kwara/models/src/models/Member';
import { V1, Guarantor, GuaranteeType, LoanApplication } from '@kwara/models/src';
import { LoanProductChargeT } from '@kwara/models/src/models/v1/LoanProducts';
import { removeNullishValues } from '@kwara/lib/src/utils/removeNullishValues';

import { NotesArg } from '.';

function prepareNotes(notes: NotesArg = {}) {
  return Object.keys(notes)
    .map(step => {
      const section = Object.keys(notes[step])[0];
      const value = notes[step][section];
      return { flow: 'loan_application', step, section, value };
    })
    .filter(obj => !!obj.value);
}

const ZERO = 0;

function setGuarantors(guarantors: Array<GuaranteeType> = [], selfGuaranteeAmount: number, memberId: string) {
  if (Number(selfGuaranteeAmount) > ZERO) {
    return [...guarantors, new Guarantor({ amount: selfGuaranteeAmount, memberId })];
  }

  return guarantors;
}

function setCharges(chargesData: LoanProductChargeT[] = []) {
  return chargesData
    .filter(charge => !charge.isMarkedForDestruction)
    .map(charge => {
      const dueDate = charge.dueDate ? formatHumanDate(new Date(charge.dueDate)) : null;

      return V1.LoanProducts.Charge.create(
        removeNullishValues({ chargeId: charge.chargeId, amount: charge.amount, dueDate })
      );
    });
}

function markInvalidNextOfKinsForDestruction(member: MemberType) {
  const validNextOfKins = member.nextOfKins.map(kin => {
    if (!kin.name || !kin.phoneNumber) {
      kin.isMarkedForDestruction = true;
    }

    return kin;
  });

  member.nextOfKins = validNextOfKins;
}

async function saveMember(member: MemberType) {
  markInvalidNextOfKinsForDestruction(member);

  const memberSaveSuccess = await member.save({ with: ['addresses', 'nextOfKins', 'idDocuments'] });

  if (!memberSaveSuccess) throw member.errors;
}

function getLoanApplication(member: MemberType) {
  return new LoanApplication(
    removeNullishValues({
      employmentStatus: member.employmentStatus,
      grossIncome: member.grossIncome,
      netIncome: member.netIncome,
      otherDeductibles: member.otherDeductibles,
      otherIncomeAmount: member.otherIncomeAmount,
      disposableIncomeAmount: member.disposableIncomeAmount,
      incomeSource: member.incomeSource,
      termsOfService: member.termsOfService
    })
  );
}

export { prepareNotes, setGuarantors, setCharges, saveMember, getLoanApplication };
