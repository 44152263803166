import { attr, belongsTo } from 'spraypaint';
import axios, { AxiosRequestConfig } from 'axios';

import { blobHeaders, handleDownload } from '@kwara/lib/src/fileDownload';

import Base, { BaseModel } from './Base';
import { GlType, UsageT } from './GeneralLedgerAccount';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});
const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

export type AmountsType = {
  debits: number;
  credits: number;
  net_change: number;
  closing_balance: number;
  opening_balance: number;
  debit_amount: number | null;
  credit_amount: number | null;
};

export type GlAccountType = {
  name: string;
  type: GlType;
  usage: UsageT;
  gl_code: string;
  glCode: string;
  encoded_key: string;
  strip_trailing_zeros: boolean;
};

export type AccountType = {
  gl_account: GlAccountType;
  amounts: AmountsType;
};

export type TotalsType = {
  opening_balances: number;
  debits: number;
  credits: number;
  net_change: number;
  closing_balances: number;
};

export type TrialBalanceTotalsType = TotalsType & {
  total_debit_amounts: number;
  total_credit_amounts: number;
};

export type TrialBalanceType = {
  accounts: AccountType[];
  totals: TrialBalanceTotalsType;
};

export type BalanceSheetType = {
  assets: AccountType[];
  liabilities: AccountType[];
  equity: AccountType[];
  totals: { assets: number; liabilities: number; equity: number; current_earnings: number };
};

export type ProfitLossType = {
  income: AccountType[];
  expenses: AccountType[];
  totals: { income: number; expenses: number; net_income: number };
};

export const AccountingReport = Base.extend({
  static: {
    jsonapiType: 'accounting_reports',
    endpoint: '/accounting/reports'
  },
  attrs: {
    startDate: attr(),
    endDate: attr(),
    balanceSheet: attr(),
    profitAndLoss: attr(),
    trialBalance: attr(),
    user: belongsTo(),
    state: attr()
  },
  methods: {
    downloadXlsx() {
      const url = `${AccountingReport.url()}/${this.id}/download.xlsx`;
      const opts = AccountingReport.fetchOptions();
      const options = blobHeaders(opts) as AxiosRequestConfig<any>;
      const fileName = `accounting_report.xlsx`;

      return axios.get(url, options).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName,
            mimeTypes
          });
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    }
  }
});

export interface AccountingReportT extends BaseModel<AccountingReportT> {
  id: string;
  trialBalance: TrialBalanceType;
  balanceSheet: BalanceSheetType;
  profitAndLoss: ProfitLossType;
  downloadXlsx: () => Promise<null>;
}
