import { LocationDescriptor } from 'history';

import { ROUTES } from '../../../../../../routes';
import { KwaraPermissionsConfigKeyType } from '../../../../../../models/Permission/types';
import { AppPermissionsType, ToHelperType } from '../../../../../../models/Permission/types';

export type Key = 'addNew' | 'more';
type ReturnType = {
  id: string;
  name: string;
  path: string | LocationDescriptor;
  permissions: KwaraPermissionsConfigKeyType[];
  hide?: boolean;
}[];

function getAddNewRoutes(AppPermissions: AppPermissionsType, isV1: boolean): ReturnType {
  return [
    {
      id: 'member',
      name: 'navigation.addNew.items.member',
      path: '/members/create/about/1',
      permissions: AppPermissions.AddMembers
    },
    {
      id: 'loanAccount',
      name: 'navigation.addNew.items.loanAccount',
      path: '/loans/create/loanPortfolio/1',
      permissions: AppPermissions.AddLoans
    },
    {
      id: 'savingsAccount',
      name: 'navigation.addNew.items.savingsAccount',
      path: '/savings/create/member/1',
      permissions: AppPermissions.AddSavings
    },
    {
      id: 'tillSession',
      name: 'navigation.addNew.items.tillSession',
      path: {
        pathname: ROUTES.till,
        state: { startTillSession: true }
      },
      permissions: AppPermissions.UseTill,
      hide: isV1
    },
    {
      id: 'batchTransactionUpload',
      name: 'navigation.addNew.items.batchTransactionUpload',
      path: ROUTES.batchTransactionImportsCreate,
      permissions: AppPermissions.CreateBatchTransactionImports,
      hide: false
    },
    {
      id: 'journalEntry',
      name: 'navigation.addNew.items.journalEntry',
      path: ROUTES.financeJournalEntriesNewConfirm,
      permissions: AppPermissions.CreateJournalEntries
    },
    {
      id: 'reconciliationReport',
      name: 'navigation.addNew.items.reconciliationReport',
      path: ROUTES.financeReconciliationsCreate,
      permissions: AppPermissions.CreateJournalEntries,
      hide: isV1
    },
    {
      id: 'directDebitReport',
      name: 'navigation.addNew.items.directDebitReport',
      path: ROUTES.financeReportsGenerateConfirm,
      permissions: AppPermissions.GenerateDirectDebitReports,
      hide: isV1
    },
    {
      id: 'sASRAForm4B',
      name: 'navigation.addNew.items.sASRAForm4B',
      path: ROUTES.financeSasraGenerateConfirm,
      permissions: AppPermissions.Finance,
      hide: isV1
    }
  ];
}

function getMoreRoutes(AppPermissions: AppPermissionsType, isCrbEnabled: boolean, isV1 = false): ReturnType {
  return [
    {
      id: 'crbSubmit',
      name: 'navigation.crbSubmit',
      path: ROUTES.credit,
      permissions: AppPermissions.CreditSubmit,
      hide: !isCrbEnabled || isV1
    },
    {
      id: 'batchTransactions',
      name: 'navigation.batchTransactions',
      path: ROUTES.allBatchTransactionImports,
      permissions: AppPermissions.ViewBatchTransactionImports
    }
  ];
}

function getVisibleRoutes(to: ToHelperType, routes: ReturnType) {
  const permittedRoutes = routes.filter(route => to(route.permissions));
  const visibleRoutes = permittedRoutes.filter(route => !route.hide);

  return visibleRoutes;
}

type GenerateModalRoutesArg = {
  key: Key;
  AppPermissions: AppPermissionsType;
  to: ToHelperType;
  isCrbEnabled: boolean;
  isV1: boolean;
};
function generateModalRoutes({ key, AppPermissions, to, isCrbEnabled, isV1 }: GenerateModalRoutesArg) {
  const routes = {
    addNew: () => getAddNewRoutes(AppPermissions, isV1),
    more: () => getMoreRoutes(AppPermissions, isCrbEnabled, isV1)
  }[key]();

  return getVisibleRoutes(to, routes);
}

export { getAddNewRoutes, getMoreRoutes, getVisibleRoutes, generateModalRoutes };
