import * as React from 'react';

import IdentityForm from '../../../../../../../MemberAdd/components/Identity';
import styles from '../index.module.scss';
import * as recordValidations from '../../../../../../../../lib/recordValidations';

const SECOND_IDENTITY_INPUT_NAME = 'member.idDocuments[1].documentId';

export function Identity(props) {
  return <IdentityForm {...props} size="stretch" className={styles.idInputWrapper} prefix="member" memberEdit />;
}

Identity.config = {
  [SECOND_IDENTITY_INPUT_NAME]: { ...recordValidations.identityDocumentValue }
};
