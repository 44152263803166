import React from 'react';

import { LoanSubstepProps } from 'LoanSharedTypes';

import { LoanAddData } from '../..';
import { useGetLoanSchedulePreview } from './useGetLoanSchedulePreview';
import { Schedule } from '../../../../LoanSchedulePreview/components/Schedule';

export type LoanSchedulePreviewProps = LoanSubstepProps<LoanAddData>;

export function LoanSchedulePreview(props: LoanSchedulePreviewProps) {
  const {
    StackChild,
    formProps: { values }
  } = props;

  const { schedule, isLoading, error } = useGetLoanSchedulePreview(values);

  return (
    <StackChild size="wide">
      <Schedule isLoading={isLoading} error={error} schedule={schedule} />
    </StackChild>
  );
}
