import { useState, useCallback } from 'react';
import last from 'lodash/fp/last';
import pick from 'lodash/fp/pick';
import compact from 'lodash/fp/compact';

import { LoanType } from '@kwara/models/src/models/Loan';
import { MemberType } from '@kwara/models/src/models/Member';
import { removeNullishValues } from '@kwara/lib/src/utils/removeNullishValues';
import { Loan, snakeCaseObjectKeys, Collateral } from '@kwara/models/src';
import { setRemittanceDetails, setDisbursementBankDetails } from '@kwara/lib/src/remittance';

import { LoanAddData } from '.';
import { Store } from '../../../models/Store/Store';
import { getSelectedFeeApplications } from '../hooks/useFeeValues';
import { useDisbursementModeAdapter } from './useDisbursementModeAdapter';
import { prepareNotes, setGuarantors, setCharges, saveMember, getLoanApplication } from './utils';
import { v1SaveAttachments } from '../../MemberAdd';

async function uploadAttachments(member: MemberType) {
  const uploaded = await v1SaveAttachments(member, member.attachments);

  if (!uploaded) throw member.errors;
}

function updateCollaterals(loan: LoanType) {
  const collaterals = loan.collaterals || [];
  const newCollaterals = collaterals.map(collateral => {
    const attributes = removeNullishValues({
      collateralNarration: collateral.collateralNarration,
      amount: collateral.amount,
      assetAttributes: removeNullishValues({
        asset_name: collateral.assetName,
        asset_bank_name: collateral.assetBankName,
        asset_bank_branch: collateral.assetBankBranch,
        asset_bank_account: collateral.assetBankAccount,
        insurance_company: collateral.insuranceCompany,
        insurance_policy_number: collateral.insurancePolicyNumber,
        land_registration: collateral.landRegistration,
        nse_stock_cds: collateral.nseStockCds
      })
    });

    return new Collateral(attributes);
  });

  loan.collaterals = newCollaterals;
}

async function saveLoan(loan: LoanType) {
  updateCollaterals(loan);

  const withIt = ['guarantors', 'loanApplication', 'charges', { collaterals: ['attachments'] }];
  const success = await loan.save({ with: withIt });

  if (!success) throw loan.errors;
}

export function useV1LoanAdd() {
  const [createdLoan, setCreatedLoan] = useState<LoanType | null>(null);
  const disbursementModes = useDisbursementModeAdapter();

  const createLoan = useCallback(
    async (data: LoanAddData, store: Store) => {
      const {
        member,
        remittance,
        bankAccounts,
        product,
        amount,
        payOffLoans,
        loanDuration,
        repaymentPeriod,
        anticipatedDisbursementDate,
        submittedAt,
        firstRepaymentDate: anticipatedFirstRepaymentDate
      } = data;

      const loanApplication = getLoanApplication(member);
      setRemittanceDetails({ remittance, data, bankAccounts, store });
      const loan = new Loan({
        amount,
        payOffLoans,
        remittance,
        loanDuration,
        repaymentPeriod,
        loanApplication,
        productId: product.id,
        accountHolderId: member.id,
        repaymentPeriodUnit: data.repaymentPeriodUnit,
        applicationNotes: prepareNotes(data.notes),
        disbursementMode: disbursementModes[data.disbursementMode],
        loanClassificationId: last(compact(data.classifications)),
        disbursementBankDetails: setDisbursementBankDetails(data, store),
        guarantors: setGuarantors(data.guarantors, data.selfGuaranteeAmount, member.id),
        charges: setCharges(data.charges),
        feeApplications: getSelectedFeeApplications({
          fees: product.fees,
          feeApplications: data.feeApplications
        }).map(snakeCaseObjectKeys),
        submittedAt,
        anticipatedDisbursementDate,
        anticipatedFirstRepaymentDate,
        ...pick(['purpose', 'specification', 'collaterals'], data)
      });

      saveMember(member);
      uploadAttachments(member);
      await saveLoan(loan);
      setCreatedLoan(loan);
    },
    [disbursementModes]
  );

  return { createLoan, createdLoan };
}
