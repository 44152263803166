import { useQuery, QueryObserverOptions } from 'react-query';

import { fetchMember } from '@kwara/models/src/models/request/hooks';
import { HttpErrorObject } from 'GlobalTypes';

const includes = ['addresses', 'next_of_kins', 'attachments', 'id_documents'];
const fields = [
  'id',
  'first_name',
  'middle_name',
  'last_name',
  'total_savings',
  'total_self_guaranteed',
  'total_loans',
  'total_loans_balance',
  'state',
  'eligible_amount',
  'standing',
  'title',
  'phone_number',
  'secondary_phone_number',
  'email',
  'date_of_birth',
  'gender',
  'marital_status',
  'employment_status',
  'terms_of_service',
  'net_income',
  'other_deductibles',
  'other_income_amount',
  'income_source',
  'business',
  'profession',
  'employer',
  'staff_id',
  'kra_pin',
  'is_staff',
  'is_delegate',
  'is_director',
  'is_group',
  'gov_employee'
];

export function useMember({ id, opts = {} }: { id: string; opts?: QueryObserverOptions }) {
  return useQuery(['member_loan_application', id, includes, fields], fetchMember, {
    retry: (failCount: number, error: HttpErrorObject) => (error.status === 404 ? false : failCount < 3),
    ...opts
  });
}
