import { attr } from 'spraypaint';

import { InferredModel } from 'GlobalTypes';

import Base from './Base';

export const NextOfKin = Base.extend({
  static: {
    jsonapiType: 'next_of_kins'
  },
  attrs: {
    name: attr(),
    phoneNumber: attr(),
    relationship: attr(),
    /**
     * V1 API only
     */
    shares: attr(),
    percentageShares: attr()
  }
});

export interface NextOfKinT extends Omit<InferredModel<NextOfKinT>, 'errors' | 'relationship'> {
  name: string;
  phoneNumber: string;
  relationship: string;
  /**
   * V1 API only
   */
  shares: number;
  percentageShares: number;
}
