import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import { Member } from '@kwara/models/src';
import { appName } from '@kwara/lib/src/utils';
import { If } from '@kwara/components/src/If/If';
import { PhoneNumberInfoType } from '@kwara/models/src/models/PhoneNumberInfo';
import { useGetPhoneNumberInfo } from '@kwara/models/src/models/PhoneNumberInfo/useGetPhoneNumberInfo';
import { InstantMobileMoneyValidator } from '@kwara/components/src/InstantMobileMoneyValidator';
import { LoanClassificationFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanClassificationFields';

import { Fees } from './Fees';
import { LoanBanner } from './LoanBanner';
import { Disbursement } from './Disbursement';
import { Subtitle } from '../../../components';
import { TwoThirdsModal } from './TwoThirdsModal';
import { useRelevantFees } from './useRelevantFees';
import { LoanAddPropTypes, LoanAddData } from '../..';
import { ERROR_TYPES } from '../../../../../lib/errorTypes';
import { LoanRepaymentFields } from './LoanRepaymentFields';
import { PrincipalAmountField } from './PrincipalAmountField';
import { Notes } from '../../../../../components/Notes/Notes';
import { useTwoThirdsRuleModal } from './useTwoThirdsRuleModal';
import { LoanConfigureUtils } from './utils/loanConfigureUtils';
import { useAuth } from '../../../../../hooks';
import { getTwoThirdsRuleValues } from '../../../utils/getTwoThirdsRuleValues';

import styles from './index.module.scss';

const DEFAULT_LOAN_AMOUNT = 0;

export const LoanConfigure = (props: LoanAddPropTypes) => {
  const {
    data,
    error,
    addDataAndContinue,
    addData,
    onChange,
    disableNextButton,
    enableNextButton,
    setError,
    formProps,
    DatePicker
  } = props;
  const { isOpen, onClose } = useTwoThirdsRuleModal(error, () => setError(null));
  const relevantFees = useRelevantFees(data);
  const auth = useAuth();

  const isV1 = auth.isV1();
  const submittedAt = formProps.values.submittedAt;
  const anticipatedDisbursementDate = formProps.values.anticipatedDisbursementDate;

  useGetPhoneNumberInfo(
    data.member.phoneNumber,
    {
      onStart: disableNextButton,
      onError: _ => enableNextButton(),
      async onSuccess(data: PhoneNumberInfoType) {
        await addData({ phoneNumberProvider: data.carrier });
        await onChange({ phoneNumberProvider: data.carrier });
        enableNextButton();
      }
    },
    !isV1
  );

  return (
    <div className="flex flex-column">
      {isV1 ? null : <LoanBanner {...props} />}
      <If
        condition={isOpen}
        do={
          <TwoThirdsModal
            data={data}
            onBypass={() => {
              addDataAndContinue({ bypass: true });
              onClose();
            }}
          />
        }
      />
      <If
        condition={!LoanConfigureUtils.hasExactLoan(data)}
        do={
          <div className={cx(styles.field, 'flex-column')}>
            <Subtitle id={'LoanAdd.LoanConfigure.subtitle.Amount'} />
            <PrincipalAmountField {...props} />
            {isV1 ? null : (
              <If condition={!get('product.instantLoan', data)} do={<LoanClassificationFields {...props} />} />
            )}
            {isV1 ? null : (
              <If
                condition={!!size(relevantFees)}
                do={
                  <>
                    <Subtitle id={'LoanAdd.LoanConfigure.subtitle.Fees'} />
                    <Fees {...props} />
                  </>
                }
              />
            )}
            <Subtitle id="LoanAdd.LoanConfigure.subtitle.Repayment" />
            <LoanRepaymentFields {...props} isV1={isV1} />
            {isV1 ? null : <Subtitle id="LoanAdd.LoanConfigure.subtitle.Disbursement" />}
            {isV1 ? null : (
              <InstantMobileMoneyValidator
                payoutProvider={data.payoutProvider}
                phoneNumberProvider={formProps.values.phoneNumberProvider}
                transactionAmountLimit={data.transactionAmountLimit}
                loanAmount={Number(formProps.values.amount) || DEFAULT_LOAN_AMOUNT}
              />
            )}
            {isV1 ? null : <Disbursement {...props} />}

            {isV1 ? (
              <>
                <DatePicker name="submittedAt" labelId="LoanSchedulePreview.Preview.submittedAt.titleId" required />
                <DatePicker
                  name="anticipatedDisbursementDate"
                  labelId="LoanSchedulePreview.Preview.anticipatedDisbursementDate.titleId"
                  disabledDays={{ before: submittedAt ? new Date(submittedAt) : null }}
                  required
                />
                <DatePicker
                  name="firstRepaymentDate"
                  labelId="LoanSchedulePreview.Preview.firstRepaymentDate.titleId"
                  disabledDays={{ before: anticipatedDisbursementDate ? new Date(anticipatedDisbursementDate) : null }}
                  required
                />
              </>
            ) : null}

            <Notes name="notes.loan_product.no_section" className="mt3" formValues={formProps.values} />
          </div>
        }
      />
    </div>
  );
};

LoanConfigure.validate = (data: LoanAddData & { isV1?: boolean }) => ({
  ...PrincipalAmountField.validate,
  ...LoanRepaymentFields.validate(data),
  ...(data.isV1 ? null : Disbursement.validate),
  ...(get('product.instantLoan', data) || data.isV1 ? null : LoanClassificationFields.validate),
  anticipatedDisbursementDate: { isRequired: (_: string, allData: LoanAddData) => allData.isV1 },
  firstRepaymentDate: { isRequired: (_: string, allData: LoanAddData) => allData.isV1 }
});

LoanConfigure.verifyTwoThirdsRule = async (data: LoanAddData) => {
  if (!appName.isSacco || data.bypass) return;

  const { member, remittance } = data;
  const { netIncome, otherIncomeAmount, otherDeductibles } = member;
  const { amount: repaymentAmount } = remittance;

  const memberIncome = await Member.select(['total_monthly_loan_payments'])
    .find(data.member.id)
    .then(res => res.data.deserialize());

  const args = {
    totalMonthlyLoanPayments: memberIncome.totalMonthlyLoanPayments,
    netIncome,
    otherIncomeAmount,
    otherDeductibles,
    repaymentAmount
  };
  const { passesTwoThirdsRule } = getTwoThirdsRuleValues(args);

  if (!passesTwoThirdsRule) throw { message: ERROR_TYPES.INCOME_TOO_LOW };
};
