import * as React from 'react';
import join from 'lodash/fp/join';
import compact from 'lodash/fp/compact';
import pipe from 'lodash/fp/pipe';
import get from 'lodash/fp/get';

import { DatePickerInputs } from '@kwara/components/src/Form';
import { SubStepComponentProps, StackChildSize } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import * as recordValidations from '../../../lib/recordValidations';

interface PersonalPropTypes extends SubStepComponentProps<{}> {
  size?: StackChildSize;
  prefix?: string;
}

const getName = (prefix: string, name: string) => pipe(compact, join('.'))([prefix, name]);

const SUPPORTED_GENDERS = ['FEMALE', 'MALE', 'NON_BINARY', 'OTHER'];
const SUPPORTED_MARITAL_STATUSES = ['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED', 'SEPARATED', 'OTHER', 'NOT_STATED'];

export const Personal = ({ size, RadioGroup, StackChild, data, prefix = '' }: PersonalPropTypes) => {
  const nameFor = name => getName(prefix, name);

  return (
    <StackChild size={size}>
      <DatePickerInputs
        name={nameFor('dateOfBirth')}
        required={true}
        placeholderId="AddMember.Personal.DateOfBirth.placeholder"
        labelId="AddMember.Personal.DateOfBirth.label"
        initial={get(nameFor('dateOfBirth'), data)}
      />

      <RadioGroup
        name={nameFor('gender')}
        labelId="AddMember.Personal.Gender.label"
        items={SUPPORTED_GENDERS.map(gender => ({ labelId: `AddMember.Personal.Gender.${gender}`, value: gender }))}
      />

      <RadioGroup
        name={nameFor('maritalStatus')}
        labelId="AddMember.Personal.MaritalStatus.label"
        items={SUPPORTED_MARITAL_STATUSES.map(status => ({
          labelId: `AddMember.Personal.MaritalStatus.${status}`,
          value: status
        }))}
      />
    </StackChild>
  );
};
Personal.validate = {
  dateOfBirth: {
    isRequired: () => true,
    ...recordValidations.dateOfBirth
  }
};
