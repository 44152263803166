import * as React from 'react';
import toUpper from 'lodash/fp/toUpper';

import Tag, { Status } from '@kwara/components/src/Tag';
import { LoanState, MemberState, SavingState } from '@kwara/models/src';

const LoanDisbursementStates = {
  PENDING_INITIAL_APPROVALS: Status.Warning,
  PENDING_MAKER_CHECKER_APPROVAL: Status.Warning,
  APPROVED: Status.Success,
  REJECTED: Status.Critical,
  IMPORTING: Status.Neutral,
  FAILED: Status.Critical
};

const InvitationStates = {
  PENDING_REDEMPTION: Status.Warning,
  REDEEMED: Status.Success,
  REVOKED: Status.Critical,
  EXPIRED: Status.Critical
};

const SavingStates = {
  ACTIVE_IN_ARREARS: Status.Warning,
  ACTIVE: Status.Success,
  APPROVED: Status.Success,
  CLOSED_REJECTED: Status.Neutral,
  CLOSED_WITHDRAWN: Status.Critical,
  CLOSED_WRITTEN_OFF: Status.Neutral,
  CLOSED: Status.Neutral,
  DORMANT: Status.Neutral,
  LOCKED: Status.Critical,
  MATURED: Status.Neutral,
  WITHDRAWN: Status.Neutral
};

const LoanStates = {
  ACTIVE_IN_ARREARS: Status.Critical,
  LIVE_IN_ARREARS: Status.Critical,
  ACTIVE_PARTIALLY_DISBURSED: Status.Warning,
  ACTIVE: Status.Success,
  LIVE: Status.Success,
  APPROVED: Status.Warning,
  CLOSED_REFINANCED: Status.Critical,
  CLOSED_REJECTED: Status.Critical,
  CLOSED_RESCHEDULED: Status.Critical,
  CLOSED_WITHDRAWN: Status.Neutral,
  CLOSED_WRITTEN_OFF: Status.Critical,
  CLOSED_REPAID: Status.Success,
  CLOSED: Status.Neutral,
  CLOSED_PAID_OFF: Status.Neutral,
  CLOSED_OBLIGATIONS_MET: Status.Neutral,
  PARTIAL_APPLICATION: Status.Neutral,
  APPROVED_AWAITING_DISBURSEMENT: Status.Warning
};

const RepaymentStates = {
  LATE: Status.Critical,
  PENDING: Status.Neutral,
  PARTIALLY_PAID: Status.Warning,
  PAID: Status.Success,
  RESCHEDULED: Status.Neutral,
  GRACE: Status.Neutral
};

const BatchTransactionImportStates = {
  REVIEWED: Status.Success,
  IN_REVIEW: Status.Neutral
};

const CashTopUpStates = {
  DECLINED: Status.Critical
};

const ConnectRegistrationStates = {
  NOT_APPLICABLE: Status.Neutral
};

export const statusToState = {
  ...BatchTransactionImportStates,
  ...InvitationStates,
  ...SavingStates,
  ...LoanStates,
  ...RepaymentStates,
  ...CashTopUpStates,
  ...ConnectRegistrationStates,
  ...LoanDisbursementStates,
  APPLICANT: Status.Neutral,
  BLACKLISTED: Status.Critical,
  EXITED: Status.Neutral,
  GUARANTOR: Status.Success,
  BORROWER: Status.Success,
  INACTIVE: Status.Neutral,
  PARTIALLY_PAID: Status.Warning,
  PENDING_APPROVAL: Status.Warning,
  PENDING_REVIEW: Status.Warning,
  APPROVED_AWAITING_DISBURSEMENT: Status.Success,
  REJECTED: Status.Critical,
  IMPORTED: Status.Success,
  IMPORTING: Status.Neutral,
  FAILED: Status.Critical,
  UPLOADED: Status.Success,
  UPLOADING: Status.Neutral,
  VALIDATING: Status.Neutral,
  SENT: Status.Success,
  GENERATED: Status.Success,
  CREATED: Status.Success,
  SUSPENSE: Status.Warning,
  INSUFFICIENT_AMOUNT: Status.Critical,
  CANCELLED: Status.Neutral,
  SUCCEEDED: Status.Success,
  ACTIVATED: Status.Success,
  NOT_ACTIVATED: Status.Critical,
  COMPLETED: Status.Success,
  COMPLETE: Status.Success,
  GENERIC_NO_OF_APPRAISALS: Status.Success
};

type Props<T> = {
  state?: LoanState | MemberState | SavingState | 'GUARANTOR' | 'APPLICANT';
  size?: string;
  values?: Record<string, T>;
};

export function StatusText<TranslationValueT = string>({ state, values }: Props<TranslationValueT>) {
  return (
    <Tag background={false} id={`StatusTag.${state}`} status={statusToState[state] || Status.Neutral} values={values} />
  );
}

export default function StatusTag<TranslationValueT = string>({
  state = 'PENDING',
  size,
  values
}: Props<TranslationValueT>) {
  const normalisedState = toUpper(state);

  return (
    <Tag
      id={`StatusTag.${normalisedState}`}
      size={size}
      status={statusToState[normalisedState] || Status.Neutral}
      values={values}
    />
  );
}
