// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import round from 'lodash/round';

import { type GuaranteeType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatusTag from '@kwara/components/src/StatusTag';
import { Card } from '@kwara/components/src/Card';

import { Currency } from '../../../components/Currency';

type Props = {
  guaranty: GuaranteeType,
  actionTo?: string,
  isV1?: boolean
};

export const LoanGuaranteedCard = ({ guaranty, actionTo, isV1 }: Props) => {
  const { loan: account } = guaranty;

  return (
    <Card
      header={get('name', account) || get('product.name', account)}
      metadata={
        <>
          <StatusTag state={get('state.current', account)} /> &middot; {get('id', account)}
        </>
      }
      actionLabelId="LoanGuaranteeCard.action"
      isHoverable
      actionTo={actionTo}
    >
      <Statistic
        compact
        title={<Text id="LoanGuaranteeCard.guaranteeAmount" />}
        size="small"
        border={true}
        value={<Currency format="currency" value={round(guaranty.amount, 2)} />}
      />
      <Statistic
        compact
        title={<Text id="LoanGuaranteeCard.guaranteeBalance" />}
        size="small"
        border={true}
        value={<Currency format="currency" value={round(guaranty.liability, 2)} />}
      />

      <Statistic
        compact
        title={<Text id="LoanGuaranteeCard.amount" />}
        size="small"
        value={<Currency format="currency" value={get('amount', account)} />}
      />

      {isV1 ? null : (
        <Statistic
          compact
          title={<Text id="LoanGuaranteeCard.totalBalance" />}
          size="small"
          border={true}
          value={<Currency format="currency" value={get('totalBalance', account)} />}
        />
      )}
    </Card>
  );
};
