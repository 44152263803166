import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { SchedulesPreview } from '@kwara/models/src/models/SchedulePreview';

import { LoanAddData } from '../..';
import { createScheduleQueryParams, QueryParams } from './utils';

export function useGetLoanSchedulePreview({
  product,
  amount,
  member,
  loanDuration,
  firstRepaymentDate,
  anticipatedDisbursementDate,
  submittedAt,
  charges,
  repaymentPeriod,
  repaymentPeriodUnit
}: LoanAddData) {
  const { enabled, queryParam } = useMemo(() => {
    const values = {
      amount,
      repaymentPeriod,
      loanDuration,
      firstRepaymentDate,
      anticipatedDisbursementDate,
      submittedAt,
      repaymentPeriodUnit,
      charges,
      memberId: member.id,
      productId: product.id
    };

    const queryParam = createScheduleQueryParams((values as unknown) as QueryParams);
    const enabled = Object.values(values).every(Boolean);

    return {
      enabled,
      queryParam
    };
  }, [
    amount,
    anticipatedDisbursementDate,
    charges,
    firstRepaymentDate,
    loanDuration,
    member.id,
    product.id,
    repaymentPeriod,
    repaymentPeriodUnit,
    submittedAt
  ]);

  const { isLoading, error, data: schedule } = useQuery(
    ['schedules_preview'],
    () => SchedulesPreview.generateV1(queryParam),
    { enabled, retry: false }
  );

  return {
    isLoading,
    schedule,
    error
  };
}
