import { attr, hasMany } from 'spraypaint';
import map from 'lodash/fp/map';
import queryString from 'query-string';

import Base from './Base';
import { PeriodUnitsT } from '..';
import Repayment, { RepaymentType } from './Repayment';
import { snakeCaseObjectKeys } from '../lib/snakeCaseObjectKeys';

function toRepayments(arr: RepaymentType[]) {
  return map(r => new Repayment(r.attributes), arr);
}

export const SchedulesPreview = Base.extend({
  static: {
    jsonapiType: 'schedules_preview',
    async generate(productId: string, params: PreviewParams) {
      const queries = queryString.stringify(snakeCaseObjectKeys(params));
      const url = `${SchedulesPreview.url()}/${productId}?${queries}`;
      const options = { ...SchedulesPreview.fetchOptions(), method: 'GET' };

      try {
        const res = await window.fetch(url, options);
        const json = await res.json();

        if (!res.ok) throw json;

        const { relationships } = json.data;

        return new SchedulesPreview({ repayments: toRepayments(relationships.repayments.data) });
      } catch (err) {
        err.messages = err.errors;
        throw err;
      }
    },

    async generateV1(queryString: string) {
      const url = `${SchedulesPreview.url()}?${queryString}`;
      const options = { ...SchedulesPreview.fetchOptions(), method: 'GET' };

      try {
        const res = await window.fetch(url, options);
        const json = await res.json();

        if (!res.ok) throw json;

        return new SchedulesPreview({ repayments: toRepayments(json.included) });
      } catch (err) {
        err.messages = err.errors;
        throw err;
      }
    }
  },

  attrs: {
    totalDue: attr(),
    repayments: hasMany()
  }
});

type PreviewParams = {
  amount: string;
  firstRepaymentDate: string;
  anticipatedDisbursementDate: string;
  repaymentInstallments: number;
  repaymentPeriod: number;
  repaymentPeriodUnit: PeriodUnitsT;
  interestRate: number;
};

export type SchedulePreviewT = {
  totalDue: string;
  repayments: Array<RepaymentType>;
};
