import * as React from 'react';

import { LoanTransactionType, LoanTransaction } from '@kwara/models/src';
import { RequestProps } from '@kwara/components/src/Loadable';
import { Checkbox } from '@kwara/components/src/Form';
import { Date, Time } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { TransactionItemType } from '@kwara/components/src/TransactionItemType';

import { useAuth } from '../../../hooks';
import { AppPermissions } from '../../../models/Permission';
import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';
import { Currency } from '../../../components/Currency';
import TransactionAdjustment from '../../../components/TransactionAdjustment';
import { shouldHideRow } from '../../../components/TransactionAdjustment/utils';
import { AdjustmentModals as V1AdjustmentModals } from '../../../components/v1/AdjustmentModals';
import { AdjustmentButtonsPropTypes } from '../../../components/TransactionAdjustment/AdjustmentButtons';

type Props = {
  transactions: null | LoanTransactionType[];
  transactionsR: RequestProps<LoanTransactionType[]>;
};

const renderRow = (
  transaction: LoanTransactionType,
  showAdjustedTransactions: boolean,
  AdjustmentButtons: React.FunctionComponent<AdjustmentButtonsPropTypes>,
  isV1: boolean = false
) => {
  const hideRow = shouldHideRow(showAdjustedTransactions, transaction);
  return (
    <If
      key={transaction.id}
      condition={!hideRow}
      do={
        <Row className={transaction.isAdjustment || transaction.wasAdjusted ? 'grey-300' : ''}>
          <Cell>
            <div className="grey-300">{transaction.id}</div>
            <div>
              <Date value={transaction.valueDate} />
            </div>
            {isV1 ? null : (
              <div>
                <Time value={transaction.valueDate} />
              </div>
            )}
          </Cell>
          <Cell>
            <div className="grey-300">{transaction.reference}</div>
            <TransactionItemType transaction={transaction} />
            {transaction.notes && <div className="grey-300">{transaction.notes}</div>}
          </Cell>
          <Cell align="right">
            <Currency value={transaction.amount} />
          </Cell>
          <Cell align="right">
            <Currency value={transaction.balance} />
          </Cell>
          <Cell align="right">
            <AdjustmentButtons transaction={transaction} />
          </Cell>
          <Cell />
        </Row>
      }
    />
  );
};
const heading = (
  <Row>
    <Heading translationId="TransactionsTable.date" />
    <Heading className="w-33" translationId="TransactionsTable.type" />
    <Heading align="right" translationId="TransactionsTable.amount" />
    <Heading align="right" translationId="TransactionsTable.balance" />
    <Heading width="175px" align="right" translationId="TransactionsTable.actions" />
    <Heading iconSpacer />
  </Row>
);

export const Transactions = ({ transactions, transactionsR }: Props) => {
  const [showAdjustedTransactions, setShowAdjustedTransactions] = React.useState(false);
  const auth = useAuth();

  const isV1 = auth.isV1();

  return (
    <TransactionAdjustment.Root adjustTransactionsPermission={AppPermissions.AdjustLoanTransactions}>
      <If
        condition={!!transactions}
        do={
          <>
            {isV1 ? (
              <V1AdjustmentModals query={transactionsR} TransactionModel={LoanTransaction} />
            ) : (
              <TransactionAdjustment.AdjustmentModals query={transactionsR} spraypaintModel={LoanTransaction} />
            )}
            <Checkbox
              name="showAdjustedTransactions"
              labelId="MemberDetail.Transactions.Subsection.Checkbox.showAdjusted"
              onChange={() => setShowAdjustedTransactions(prev => !prev)}
            />
            <TransactionsTable
              heading={heading}
              renderRow={(transaction: LoanTransactionType) =>
                renderRow(transaction, showAdjustedTransactions, TransactionAdjustment.AdjustmentButtons, isV1)
              }
              transactions={transactions}
            />
          </>
        }
      />
    </TransactionAdjustment.Root>
  );
};
