import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { JournalEntryT } from '@kwara/models/src';

import Visible from '../../../../../components/Visible';
import OverviewListV2 from '../../../../../components/OverviewListV2';

import { JournalEntriesTable } from './Table';
import { financePath } from '../../../../../lib/urls';
import { AppPermissions } from '../../../../../models/Permission';

type Props = {
  transactions: JournalEntryT[];
  actions?: (React.ReactElement<typeof Button> | React.ReactElement<typeof Visible>)[] | React.ReactElement;
  filterValue: string;
  totalResults: number;
  isLoading: boolean;
  hasMore: boolean;
  onNext(): void;
  where?: {
    from: string;
    to: string;
    gl_code?: string;
  };
};

export function JournalEntriesList({ transactions = [], totalResults, isLoading, ...r }: Props) {
  const actions = React.useMemo(() => {
    return [
      <Visible key="new" to={AppPermissions.CreateJournalEntries}>
        <Button key="new" type="primary" to={financePath({ baseExtension: 'journalEntries', action: 'new' })}>
          <Text id="Finance.JournalEntries.add" />
        </Button>
      </Visible>
    ];
  }, []);

  return (
    <OverviewListV2
      withFilter={true}
      totalNumResults={totalResults}
      items={transactions}
      filterDisabled={isLoading}
      actions={actions}
      labelId="Finance.JournalEntries.Transactions.List.label"
      table={<JournalEntriesTable isLoading={isLoading} transactions={transactions} {...r} />}
    />
  );
}
