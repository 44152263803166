import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';
import { ApiError } from 'GlobalTypes';
import { UseQueryResult } from 'react-query';

import Actionable from '@kwara/components/src/Actionable';

import { SavingType } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';
import { useLoanProducts } from '@kwara/models/src/models/request/hooks';
import { MemberEligibilityT } from '@kwara/models/src/models/MemberEligibility';

import { LoanAddPropTypes } from '../..';
import { useActions } from './useActions';
import { LoanCard } from '../../../../../components/LoanCard';
import { useFilterBySearchTerm } from './useFilterBySearchTerm';
import { useMemberEligibility, useSavingsForMember } from '../../../../../hooks';

import styles from './index.module.scss';

export function LoanProduct({
  TextField,
  formProps,
  data: { member },
  addData,
  onChange,
  addDataAndContinue
}: LoanAddPropTypes) {
  const filterBySearchTerm = useFilterBySearchTerm();
  const loanProductsRequest = useLoanProducts('product_config');
  const memberEligibilityRequest = useMemberEligibility({ memberId: member.id });
  const savingsForMemberRequest = useSavingsForMember(member.id, { state: 'ACTIVE' });
  const { onSelect, addLoanProductEligibility } = useActions({
    addData,
    onChange,
    addDataAndContinue,
    savingsForMemberRequest
  });

  return (
    <div>
      <TextField name="query" leftGlyph="Search" placeholderId="LoanAdd.LoanProduct.search.placeholder" />
      <ul className={styles['product-list']}>
        <Loadable {...memberEligibilityRequest}>
          {(eligibilities: Array<MemberEligibilityT>) => (
            <Loadable {...loanProductsRequest}>
              {(products: Array<LoanProductType>) => {
                const filteredProducts = filterBySearchTerm(products, get('values.query', formProps));
                return filteredProducts.map(loanProduct => {
                  addLoanProductEligibility(loanProduct, eligibilities);
                  return (
                    <li className={cx('list', styles['item'])} key={loanProduct.id}>
                      <Actionable
                        className="b--none tl w-100 br4 pointer pa0 ma0"
                        onClick={() => onSelect(loanProduct)}
                      >
                        <LoanCard loan={loanProduct} showInfo isLoanProductData />
                      </Actionable>
                    </li>
                  );
                });
              }}
            </Loadable>
          )}
        </Loadable>
      </ul>
    </div>
  );
}

export function LoanProductV1({ TextField, formProps, addData, onChange, addDataAndContinue }: LoanAddPropTypes) {
  const filterBySearchTerm = useFilterBySearchTerm();
  const loanProductsRequest = useLoanProducts('product_config');
  const { onSelect } = useActions({
    addData,
    onChange,
    addDataAndContinue,
    savingsForMemberRequest: { isFetched: false, data: null } as UseQueryResult<SavingType[], ApiError>
  });

  return (
    <div>
      <TextField name="query" leftGlyph="Search" placeholderId="LoanAdd.LoanProduct.search.placeholder" />
      <ul className={styles['product-list']}>
        <Loadable {...loanProductsRequest}>
          {(products: Array<LoanProductType>) => {
            const filteredProducts = filterBySearchTerm(products, get('values.query', formProps));
            return filteredProducts
              .filter(loanProduct => loanProduct.activated)
              .map(loanProduct => {
                return (
                  <li className={cx('list', styles['item'])} key={loanProduct.id}>
                    <Actionable
                      disabled={!loanProduct.activated}
                      className="b--none tl w-100 br4 pointer pa0 ma0"
                      onClick={() => onSelect(loanProduct)}
                    >
                      <LoanCard loan={loanProduct} showInfo isLoanProductData isV1={true} />
                    </Actionable>
                  </li>
                );
              });
          }}
        </Loadable>
      </ul>
    </div>
  );
}
