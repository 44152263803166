import React from 'react';
import composeClassnames from 'classnames';
import { Link, Prompt } from 'react-router-dom';

import zIndices from '@kwara/lib/src/zIndices';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';

import { Key, generateModalRoutes } from './generateModalRoutes';
import { useAuth, usePermissions } from '../../../../../../hooks';
import { useSaccoProfileContext } from '../../../../../../models/Profile/ProfileProvider';

import styles from './index.module.scss';

type ExtraActionModalPropTypes = {
  isOpen: boolean;
  onClose(): void;
  callee: Key;
};

export function ExtraActionModal({ isOpen, callee, onClose }: ExtraActionModalPropTypes) {
  const [headingId, bodyId] = useUniqueIds(2);
  const { AppPermissions, permission } = usePermissions();
  const store = useSaccoProfileContext();
  const auth = useAuth();

  const isCrbEnabled = store.isCrbCheckEnabled;
  const routes = generateModalRoutes({
    key: callee,
    AppPermissions,
    to: permission.to,
    isCrbEnabled,
    isV1: auth.isV1()
  });

  return (
    <If
      condition={isOpen}
      do={
        <>
          <Prompt
            message={function closeModalOnPageLive() {
              onClose();

              return true;
            }}
          />

          <Overlay>
            <ModalWrapper
              elementType="extraactionportal"
              id={bodyId}
              isOpen={isOpen}
              onClose={onClose}
              aria-labelledby={headingId}
              className={composeClassnames(styles['container'], zIndices.Max)}
              closeOnOutClick
            >
              <h2 id={headingId} className="kw-text-large" data-testid="extraActionModalHeading">
                <Text id={`navigation.${callee}.heading`} />
              </h2>

              <ul>
                {routes.map(({ id, name, path }) => {
                  return (
                    <li key={id} tabIndex={0}>
                      <Link to={path} className="kw-text-medium kw-weight-regular">
                        <Text id={name} />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </ModalWrapper>
          </Overlay>
        </>
      }
    />
  );
}
