import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Checkbox } from '@kwara/components/src/Form';
import { Date, Time } from '@kwara/components/src/Intl';
import { RequestProps } from '@kwara/components/src/Loadable';
import { TransactionItemType } from '@kwara/components/src/TransactionItemType';
import { SavingsTransaction, SavingsTransactionType } from '@kwara/models/src';

import TransactionsTable from '../../../components/TransactionsTable';
import TransactionAdjustment from '../../../components/TransactionAdjustment';

import { useAuth } from '../../../hooks';
import { Currency } from '../../../components/Currency';
import { AppPermissions } from '../../../models/Permission';
import { Cell, Heading, Row } from '../../../components/Table';
import { shouldHideRow } from '../../../components/TransactionAdjustment/utils';
import { AdjustmentModals as V1AdjustmentModals } from '../../../components/v1/AdjustmentModals';
import { AdjustmentButtonsPropTypes } from '../../../components/TransactionAdjustment/AdjustmentButtons';

type Props = {
  transactions: null | SavingsTransactionType[];
  transactionsR: RequestProps<SavingsTransactionType[]>;
};

const heading = (
  <Row>
    <Heading translationId="TransactionsTable.date" />
    <Heading className="w-33" translationId="TransactionsTable.type" />
    <Heading align="right" translationId="TransactionsTable.amount" />
    <Heading align="right" translationId="TransactionsTable.balance" />
    <Heading width="175px" align="right" translationId="TransactionsTable.actions" />
    <Heading iconSpacer />
  </Row>
);

function renderRow(
  transaction: SavingsTransactionType,
  showAdjustedTransactions: boolean,
  AdjustmentButtons: React.FunctionComponent<AdjustmentButtonsPropTypes>,
  isV1: boolean
) {
  const hideRow = shouldHideRow(showAdjustedTransactions, transaction);

  return (
    <If
      key={transaction.id}
      condition={!hideRow}
      do={
        <Row className={transaction.isAdjustment || transaction.wasAdjusted ? 'grey-300' : ''}>
          <Cell>
            <div className="grey-300">{transaction.id}</div>
            <div>
              <Date value={transaction.valueDate} />
            </div>
            {isV1 ? null : (
              <div>
                <Time value={transaction.valueDate} />
              </div>
            )}
          </Cell>
          <Cell>
            <div className="grey-300">{transaction.reference}</div>
            <TransactionItemType transaction={transaction} />
            <If condition={!!transaction.notes} do={<div className="grey-300">{transaction.notes}</div>} />
          </Cell>
          <Cell align="right">
            <Currency value={transaction.amount} />
          </Cell>
          <Cell align="right">
            <Currency value={transaction.balance} />
          </Cell>
          <Cell align="right">
            <AdjustmentButtons transaction={transaction} />
          </Cell>
          <Cell />
        </Row>
      }
    />
  );
}

export const Transactions = ({ transactions, transactionsR }: Props) => {
  const [showAdjustedTransactions, setShowAdjustedTransactions] = React.useState(false);
  const auth = useAuth();

  const isV1 = auth.isV1();

  return (
    <If
      condition={!!transactions}
      do={
        <TransactionAdjustment.Root adjustTransactionsPermission={AppPermissions.AdjustSavingsTransactions}>
          {isV1 ? (
            <V1AdjustmentModals query={transactionsR} TransactionModel={SavingsTransaction} />
          ) : (
            <>
              <TransactionAdjustment.AdjustmentModals query={transactionsR} spraypaintModel={SavingsTransaction} />
            </>
          )}
          <Checkbox
            name="showAdjustedTransactions"
            labelId="MemberDetail.Transactions.Subsection.Checkbox.showAdjusted"
            onChange={() => setShowAdjustedTransactions(prev => !prev)}
          />
          <TransactionsTable
            heading={heading}
            renderRow={(transaction: SavingsTransactionType) =>
              renderRow(transaction, showAdjustedTransactions, TransactionAdjustment.AdjustmentButtons, isV1)
            }
            transactions={transactions}
          />
        </TransactionAdjustment.Root>
      }
    />
  );
};
