import React from 'react';
import map from 'lodash/fp/map';
import { Form } from 'react-final-form';

import { GlAccountT, ReportFieldT } from '@kwara/models/src';
import { SubscribedCombobox } from '@kwara/components/src/Form';

export function ReportConfigForm({
  field,
  onAdd,
  glAccounts = [],
  scalar
}: {
  field: ReportFieldT;
  onAdd(data): void;
  glAccounts: GlAccountT[];
  scalar: number;
}) {
  return (
    <Form
      onSubmit={(data: FormData, form) => {
        onAdd(data);
        form.submit();
      }}
      initialValues={{ field, scalar }}
      render={({ handleSubmit }) => {
        const comboboxData = map(
          glAccount => ({ value: glAccount.glCode, label: `${glAccount.glCode} (${glAccount.glName})` }),
          glAccounts
        );

        return (
          <form>
            <SubscribedCombobox
              placeholderId="SasraReportConfig.Form.glAccount.option.default"
              size={'medium'}
              name="glAccountId"
              data={comboboxData}
              onChange={handleSubmit}
            />
          </form>
        );
      }}
    />
  );
}
