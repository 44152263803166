import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { OperationalReportT } from '@kwara/models/src';

import Visible from '../../components/Visible';
import OperationalReports from '../../components/OperationalReports';

import { Form } from './components/Form';
import { BodyContentWrapper } from '../../layouts';
import { usePermissions, useGlAccounts } from '../../hooks';
import { useOperationalReportsView } from '../../components/OperationalReports/useOperationalReportsView';
import {GlAccountType} from "../../../../shared-models/src/models/AccountingReport";

type Payload = { filters: { from: string; to: string; gl_account?: string | null } };

type LayoutPropTypes = {
  reports: OperationalReportT[];
  isLoadingReports: boolean;
  hasMoreReports: boolean;
  onNext(): void;
  onReportDownload(report: OperationalReportT): void;
  isReportDownloading: boolean;
  onGenerate(data: Payload): void;
  glAccounts: GlAccountType[];
  isLoadingGlAccounts: boolean;
};

export function Layout({
  reports,
  hasMoreReports,
  isLoadingReports,
  isReportDownloading,
  onNext,
  onReportDownload,
  onGenerate,
  glAccounts,
  isLoadingGlAccounts
}: LayoutPropTypes) {
  const { AppPermissions } = usePermissions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Root>
          <div className="flex justify-between">
            <div />
            <Visible to={AppPermissions.CreateJournalEntriesReports}>
              <Form glAccounts={glAccounts} isLoadingGlAccounts={isLoadingGlAccounts} onGenerate={onGenerate} />
            </Visible>
          </div>
        </BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <div className="kw-text-x-large kw-weight-bold pa3">
            <Text id={'JournalEntriesReports.ReportsIndex.title'} />
          </div>
          <OperationalReports.Table
            reports={reports}
            isLoading={isLoadingReports}
            hasMore={hasMoreReports}
            onNext={onNext}
            onDownload={onReportDownload}
            isDownloading={isReportDownloading}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function JournalEntriesReports() {
  const {
    allReports,
    isLoadingReports,
    hasMoreReports,
    onNext,
    onGenerate,
    onDownload,
    isReportDownloading
  } = useOperationalReportsView('journal_entries_report');

  const { data: glAccounts = [], isLoading: isLoadingGlAccounts } = useGlAccounts();

  return (
    <Layout
      reports={allReports}
      hasMoreReports={hasMoreReports}
      isLoadingReports={isLoadingReports}
      isReportDownloading={isReportDownloading}
      onNext={onNext}
      onReportDownload={onDownload}
      onGenerate={onGenerate as (data: Payload) => void}
      glAccounts={isLoadingGlAccounts ? [] : glAccounts}
      isLoadingGlAccounts={isLoadingGlAccounts}
    />
  );
}
