import React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

import { LoanProductType } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedDatePicker, SubscribedCombobox } from '@kwara/components/src/Form';
import createValidator from '@kwara/lib/src/validator';
import { yesterday } from '@kwara/lib/src/dates';

export type Payload = { filters: {}; toDate: Date };

type Props = {
  onGenerate(data: Payload): void;
  products: LoanProductType[];
  isLoadingProducts: boolean;
};

const rules = {
  'filters.loan_product_key': {
    isRequired: () => true
  },
  toDate: {
    isRequired: () => true
  }
};

export function Form({ onGenerate, products, isLoadingProducts }: Props) {
  const comboboxData = products.map(product => ({ value: product.encodedKey || product.id, label: product.name }));

  return (
    <ReactFinalForm
      onSubmit={(data: Payload, form) => {
        onGenerate(data);
        form.reset();
      }}
      validate={createValidator(rules)}
      render={({ form, handleSubmit, valid, submitting }) => {
        return (
          <form
            className="w-100 flex justify-left items-center"
            onSubmit={handleSubmit}
            aria-label="Create Loan Listing Report Form"
          >
            <div className="relative dib mr3">
              <SubscribedCombobox
                compact
                margin={false}
                size="medium"
                placeholderId="LoanListingReports.Form.products.option.default"
                name="filters.loan_product_key"
                data={comboboxData}
                showInfo={false}
              />
            </div>
            <div className="relative dib mr3">
              <SubscribedDatePicker
                compact
                margin={false}
                showInfo={false}
                required
                name="toDate"
                leftGlyph="LoanListingReports.Form.toDate.label"
                disabledDays={{ after: yesterday() }}
              />
            </div>

            <Button disabled={!valid || submitting || isLoadingProducts} type="primary" onClick={form.submit}>
              <Text id="LoanListingReports.Form.submit.generate" />
            </Button>
          </form>
        );
      }}
    />
  );
}
