import { hasMany } from 'spraypaint';
import queryString from 'query-string';
import { attr } from 'spraypaint';

import { InferredModel } from 'GlobalTypes';

import Base from '../../Base';

import { downloadExcel } from '../utils';
import { TrialBalanceRowT } from './Row';

export type DownloadXlsxParams = {
  start_date: string;
  end_date: string;
};

export const TrialBalance = Base.extend({
  static: {
    jsonapiType: 'abstract_trial_balance_reports',
    endpoint: '/accounting/reports/trial_balance',
    downloadXlsx(params: DownloadXlsxParams) {
      const queries = queryString.stringify(params);
      const url = `${TrialBalance.url()}.xlsx?${queries}`;
      const opts = TrialBalance.fetchOptions();
      const fileName = `trial_balance.xlsx`;

      return downloadExcel({ fileName, url, opts });
    }
  },
  attrs: {
    rows: hasMany('abstract_trial_balance_report_rows'),
    sumOpeningBalance: attr(),
    sumDebitAmount: attr(),
    sumCreditAmount: attr(),
    sumNetChange: attr(),
    sumClosingBalance: attr()
  }
});

export interface TrialBalanceT extends Omit<InferredModel<typeof TrialBalance>, 'errors'> {
  rows: TrialBalanceRowT[];
  sumOpeningBalance: number;
  sumDebitAmount: number;
  sumCreditAmount: number;
  sumNetChange: number;
  sumClosingBalance: number;
}
