import { useQuery } from 'react-query';

import { RepaymentAmount } from '@kwara/models/src';

import { useAuth } from '../../../../../hooks';

async function getRepaymentAmount({ queryKey }) {
  const [_, loanProductId, payload] = queryKey;

  const scope = RepaymentAmount(loanProductId);
  const res = await scope.where(payload).find();
  return res.data;
}

type Payload = Partial<{
  principal_amount: string;
  additional_fees: number;
  loan_duration: number;
  repayment_frequency_unit: 'MONTHS' | 'WEEKS' | 'DAYS';
}>;

export const useRepaymentAmount = (loanProductId: string, payload: Payload, enabled: boolean = true) => {
  const auth = useAuth();

  const isV1 = auth.isV1();
  const allParamsPresent = !!payload.principal_amount;
  return useQuery(['calculated_loan_repayment', loanProductId, payload], getRepaymentAmount, {
    enabled: !isV1 && allParamsPresent && enabled
  });
};
