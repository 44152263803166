import React, { useState, useMemo } from 'react';

import { LoanFormData, LoanSubstepProps } from 'LoanSharedTypes';

import { LoanRemittanceT } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { MemberType } from '@kwara/models/src/models/Member';
import { createContext } from '@kwara/lib/src/utils/createContext';
import { LoanProductChargeT } from '@kwara/models/src/models/v1/LoanProducts';
import { StepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/latest';
import { FullPageLoader } from '@kwara/components/src/Loadable/FullPageLoader';

import { steps } from './config';
import { useLoanAdd } from './useLoanAdd';
import { loanPath } from '../../../lib/urls';
import { WizardPageProps } from '../..';
import { useAuth } from '../../../hooks';
import { useGetInitialData } from './useGetInitialData';
import { LoanCompletion } from './components/Completion/Completion';
import { BUTTONS_APPEAR_AS, VerifyIdentityContextProvider } from '../../MemberAdd';

export type NotesArg = {
  member_profile?: {
    income?: string;
  };
  pay_off?: {
    no_section?: string;
  };
  loan_product?: {
    no_section?: string;
  };
};

export interface LoanAddData extends LoanFormData {
  bypass?: boolean;
  transactionAmountLimit: number;
  payoutProvider: string | null;
  phoneNumberProvider: string | null;
  remittance: LoanRemittanceT;
  isV1: boolean;
  // V1 api attrs
  productName: string;
  firstRepaymentDate: string;
  anticipatedDisbursementDate: string;
  submittedAt: string;
  charges: LoanProductChargeT[];
  chargeOptions: LoanProductChargeT[];
  notes: NotesArg;
}

export interface LoanAddPropTypes extends LoanSubstepProps<LoanAddData> {}

export type LoanAddContextType = {
  hasAddedMember?: boolean;
  setHasAddedMember: React.Dispatch<React.SetStateAction<boolean>>;
};

const [LoanAddContextProvider, useLoanAddContext] = createContext<LoanAddContextType>('LoanAddContext');

function LoanAdd({ baseUrl, match, store }: WizardPageProps<{}>) {
  const [hasAddedMember, setHasAddedMember] = useState(false);
  const auth = useAuth();
  const [appearAs, setAppearAs] = useState(BUTTONS_APPEAR_AS.VERIFY);
  const loanAddContextValue = useMemo(() => ({ setHasAddedMember }), [setHasAddedMember]);
  const verifyIdContextValue = useMemo(() => ({ appearAs, setAppearAs }), [appearAs]);
  const { r, createLoan, createdLoan } = useLoanAdd();
  const getInitialData = useGetInitialData();

  const isV1 = auth.isV1();

  return (
    <Loadable {...r} loading={<FullPageLoader />}>
      {(member: MemberType) => {
        return (
          <VerifyIdentityContextProvider value={verifyIdContextValue}>
            <LoanAddContextProvider value={loanAddContextValue}>
              <Wizard
                analyticsId="LoanAdd"
                startId="loanPortfolio"
                titleId="LoanAdd.titleWithFullName"
                steps={steps(member, hasAddedMember, isV1) as StepConfig}
                baseUrl={baseUrl}
                cancelReturnsTo={loanPath()}
                currentStep={match.params.step}
                initialData={getInitialData(member, isV1)}
                onSubmit={data => createLoan(data, store)}
                currentSubStep={getSubstepFromMatchParams(match.params)}
                onRenderCustomCompletionScreen={(onCancel, data) => (
                  <LoanCompletion
                    onCancel={onCancel}
                    onConfirm={async () => {
                      await createdLoan.downloadApplicationPdf();
                      onCancel();
                    }}
                    analyticsId="LoanAdd"
                    data={data}
                  />
                )}
              />
            </LoanAddContextProvider>
          </VerifyIdentityContextProvider>
        );
      }}
    </Loadable>
  );
}

export { LoanAdd, useLoanAddContext };
