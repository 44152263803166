//@flow

import * as React from 'react';

import { LoanAddData } from '../../../../..';
import { Address as AddressBase } from '../../../../../../../MemberAdd/components/Address';

export function Address(props) {
  return <AddressBase {...props} size="stretch" prefix="member" />;
}

Address.validate = {
  'member.addresses[0].postalAddress': {
    isRequired: (_: string, data: LoanAddData) => !data.isV1
  },
  'member.addresses[0].physicalAddress': {
    isRequired: (_: string, data: LoanAddData) => !data.isV1
  }
};
