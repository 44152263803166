import React from 'react';
import cx from 'classnames';

import { Text, Currency } from '@kwara/components/src/Intl';
import { formatHumanDate } from '@kwara/lib/src/dates';
import { CHARGE_TIMES, LoanProductChargeT } from '@kwara/models/src/models/v1/LoanProducts';

import { LoanAddData } from '../..';
import { EditChargeWidget, OnChangeCharge } from './EditChargeWidget';
import { default as BaseTable, Heading, Row, Cell, Footer } from '../../../../../components/Table';

import styles from './Table.module.css';

type ChargePropTypes = {
  charges?: LoanProductChargeT[];
  anticipatedDisbursementDate: string;
  onRemove(chargeId: string): Promise<LoanAddData>;
  onRestore(chargeId: string): Promise<LoanAddData>;
  onChangeCharge: OnChangeCharge;
};

export function Table({
  anticipatedDisbursementDate,
  charges = [],
  onRemove,
  onRestore,
  onChangeCharge
}: ChargePropTypes) {
  const heading = (
    <Row>
      <Heading translationId="LoanAdd.Charges.Table.header.name" />
      <Heading translationId="LoanAdd.Charges.Table.header.amount" />
      <Heading translationId="LoanAdd.Charges.Table.header.calculationType" />
      <Heading translationId="LoanAdd.Charges.Table.header.chargeTime" />
      <Heading translationId="LoanAdd.Charges.Table.header.dueDate" />
      <Heading translationId="LoanAdd.Charges.Table.header.actions" />
      <Heading />
    </Row>
  );

  return (
    <BaseTable
      heading={heading}
      footer={<Footer items={charges} colSpan={7} translationBaseId="LoanAdd.Charges.Table.footer" />}
    >
      {charges.map((charge, index) => {
        const isSpecifiedDueDate = charge.chargeTime === CHARGE_TIMES.specifiedDueDate;
        const dueDate = isSpecifiedDueDate ? formatHumanDate(new Date(charge.dueDate)) : '-';

        return (
          <Row key={charge.chargeId}>
            <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>{charge.name}</Cell>
            <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>
              <Currency value={charge.amount} />
            </Cell>
            <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>
              {charge.calculationType}
            </Cell>
            <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>{charge.chargeTime}</Cell>
            <Cell className={cx({ [styles['crossed-out']]: charge.isMarkedForDestruction })}>{dueDate}</Cell>
            <Cell>
              {charge.isMarkedForDestruction ? (
                <button className={cx(styles.button, 'indigo-500')} onClick={() => onRestore?.(charge.chargeId)}>
                  <Text id="LoanAdd.Charges.Table.Restore.button" />
                </button>
              ) : (
                <button className={cx(styles.button, 'red-500')} onClick={() => onRemove?.(charge.chargeId)}>
                  <Text id="LoanAdd.Charges.Table.Remove.button" />
                </button>
              )}
            </Cell>
            <Cell>
              <EditChargeWidget
                charge={charge}
                chargePrefix={`charges[${index}]`}
                anticipatedDisbursementDate={anticipatedDisbursementDate}
                onChangeCharge={onChangeCharge}
              />
            </Cell>
          </Row>
        );
      })}
    </BaseTable>
  );
}
